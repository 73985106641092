import styled from "styled-components"

const Container = styled.section`
  background-color: #fafafa;
  padding: 45px 0;

  @media (min-width: 800px) {
    padding: 30px 0;
  }
`

export default Container
