import styled from "styled-components"

const Title = styled.h4`
  color: var(--black);
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.64px;
  margin: 5px 0 0;
  text-transform: uppercase;
  text-align: center;
`

export default Title
