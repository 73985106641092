module.exports = [{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://fonts.gstatic.com","https://fonts.googleapis.com"],"custom":[{"name":["vieren-type-regular"],"file":"/fonts/vieren-type-regular.css"}],"web":[{"name":"Lato","file":"https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"VIEREN","short_name":"VIEREN","description":"Limited edition luxury brand Swiss watches to celebrate time, designed by Sunny Fong of Project Runway. Be first on the VIEREN List for an invite to our launch.","lang":"en","start_url":"/","icon":"src/images/logos/favicon.png","background_color":"#000","theme_color":"#000","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ef78973bce2531646f67f023462d3753"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cms.vieren.co/graphql","verbose":true,"debug":{"preview":true,"timeBuildSteps":true,"graphql":{"showQueryVarsOnError":true,"showQueryOnError":true,"writeQueriesToDisk":true,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"printIntrospectionDiff":false},"disableCompatibilityCheck":false,"throwRefetchErrors":false},"develop":{"hardCacheData":true,"hardCacheMediaFiles":true,"nodeUpdateInterval":5000},"production":{"hardCacheMediaFiles":true,"allow404Images":true,"allow401Images":true},"schema":{"timeout":600000,"perPage":100,"requestConcurrency":20,"previewRequestConcurrency":5,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"type":{"Category":{"excludeFieldNames":["ancestors","children","contentNodes","taxonomy","taxononmyName","termGroupId","termTaxonomyId"]},"CheckoutPage":{},"Comment":{"exclude":true},"ContentType":{"exclude":true},"Gift":{"excludeFieldNames":["terms"]},"GiftStoriesCategory":{"excludeFieldNames":["contentNodes","gifts"]},"MediaItem":{"createFileNodes":false,"excludeFieldNames":["ancestors","children","commentCount","commentStatus","parent","template"],"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"Menu":{"exclude":true},"MenuItem":{"exclude":true},"Page":{"excludeFieldNames":["ancestors","children","featuredImage","featuredImageDatabaseId","featuredImageId","parent"]},"Post":{"excludeFieldNames":["featuredImage","featuredImageDatabaseId","featuredImageId","terms"]},"PostFormat":{"exclude":true},"ProductPage":{"excludeFieldNames":["terms"]},"ProductPageCategory":{"excludeFieldNames":["contentNodes","productPages","taxonomyName","termGroupId"]},"SiteMesssage":{"excludeFieldNames":["template"]},"Tag":{"exclude":true},"Taxonomy":{"exclude":true},"User":{"exclude":true},"UserRole":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K6TF9CT","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"selfHostedOrigin":"https://measure.vieren.co","routeChangeEventName":"gatsby-route-change","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
